import React, { useState, useEffect, useRef } from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  const [result, setResult] = useState(null);
  const formRef = useRef(null);
  const resultRef = useRef(null);

  const verifyCertificate = () => {
    const category = document.getElementById('category').value;
    const number = document.getElementById('number').value;

    // Display "Searching data..." message
    setResult('fetching');

    // Simulate loading delay of 3 to 5 seconds
    const loadingDelay = Math.floor(Math.random() * 3000) + 3000;
    setTimeout(() => {
      // Load JSON data based on category and number
      const url = category + '.json';
      fetch(url)
        .then(response => response.json())
        .then(data => {
          const certificate = data[number];
          if (certificate) {
            setResult(certificate);
          } else {
            setResult('invalid');
          }
        })
        .catch(error => {
          setResult('error');
        });
    }, loadingDelay);
  };

  useEffect(() => {
    // Scroll to the top of the result div when the result changes
    const resultDiv = resultRef.current;
    const formElement = formRef.current;
    if (resultDiv && formElement) {
      resultDiv.scrollIntoView({ behavior: 'smooth' });
      formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [result]);

  const displayCertificate = certificate => {
    return (
      <div className="result">
        <h2>Verification Result</h2>
        <h3 style={{ color: 'green' }}>
          <img
            src="https://media1.giphy.com/media/zaD5opvkEON9DvegA0/200w.webp?cid=ecf05e4716ftlwpqr4lp4cuwgme588e1w12eru8d4uggtb8k&ep=v1_stickers_search&rid=200w.webp&ct=s"
            alt="Valid"
            className="valid-sign"
            style={{ width: '30px' }}
          />
          Document is valid.
        </h3>

        <img src={certificate.photo} alt="Photo" className="center" />

        <table>
          <thead>
            <tr>
              <th>Issue Date</th>
              <th>Name</th>
              <th>Purpose</th>
              <th>Issuer</th>
              <th>Issuer Designation</th>
              <th>Issuer Contact</th>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{certificate.date}</td>
              <td>{certificate.name}</td>
              <td>{certificate.purpose}</td>
              <td>{certificate.issuer}</td>
              <td>{certificate.issuer_designation}</td>
              <td>{certificate.contact}</td>
              <td style={{ color: certificate.comment ? 'blue' : 'black' }}>
                {certificate.comment}
              </td>
            </tr>
          </tbody>
        </table>
        <button className="reload" onClick={() => setResult(null)}>Reload</button>

      </div>
    );
  };

  const renderResult = () => {
    if (result === 'fetching') {
      return (
        <div className="result">
          <h2>Fetching data...</h2>
        </div>
      );
    } else if (result === 'invalid') {
      return (
        <div className="result">
          <h3 style={{ color: 'red' }}>
            <img
              src="https://media3.giphy.com/media/fJxnT4k3H0aZ8JwOII/200w.webp?cid=ecf05e4795ibq4tavtcmxcabygiz2zc8ixqkqitq5iu9bjdl&ep=v1_stickers_search&rid=200w.webp&ct=s"
              alt="Invalid"
              className="valid-sign"
              style={{ width: '30px' }}
            />
            Document is invalid.
          </h3>
          <button className="reload" onClick={() => setResult(null)}>Reload</button>

        </div>
      );
    } else if (result === 'error') {
      return (
        <div className="result">
          <h3 style={{ color: 'red' }}>
            <img
              src="https://media3.giphy.com/media/fJxnT4k3H0aZ8JwOII/200w.webp?cid=ecf05e4795ibq4tavtcmxcabygiz2zc8ixqkqitq5iu9bjdl&ep=v1_stickers_search&rid=200w.webp&ct=s"
              alt="Error"
              className="valid-sign"
              style={{ width: '30px' }}
            />
            Error loading data.
          </h3>
          <button className="reload" onClick={() => setResult(null)}>Reload</button>

        </div>
      );
    } else if (result) {
      return displayCertificate(result);
    } else {
      return null;
    }
  };

  return (
    <div className="App">
      <header>
        <img src={logo} alt="Logo" className="logo" />
        <h1>Anun Verification System</h1>
      </header>
      <div className="scrollable-container">
        <div id="result" ref={resultRef}>
          {result && renderResult()}
        </div>
        {!result && (
          <div className="form" ref={formRef}>
            <label htmlFor="category">Verification Category</label>
            <select id="category">
              {/*<option value="notice">Notice</option>
              <option value="employee">Employee Information</option>*/}
              <option value="employee_certificate">Employee Certificate </option>
              <option value="order_verification">Order</option>
              <option value="noc">No Objection Certificate</option>
            </select>
            <br />
            <label htmlFor="number">Document Serial No.</label>
            <input type="number" id="number" />
            <br />
            <button onClick={verifyCertificate}>Verify</button>
            <h2 className="warning">Select Verification Category carefully</h2>
          </div>
          
        )}
        

      </div>
    </div>
  );
}

export default App;
